.popupBg {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 255;

	> div.popup {
		position: absolute;
		top: 50%;
		left: 50%;
		width: min(100% - 25px, 900px);
		min-height: 100px;
		background-color: white;
		border-radius: 4px;
		transform: translate(-50%, -50%);
		padding: 25px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
	}
}
