.perfChartContainer {
	width: 100%;

	> div.nav {
		width: 100%;
		padding: 12.5px 25px;
		background: #fafafa;
		border-top: 1px solid rgba(0, 0, 0, 0.05);
		font-size: 12px;
		text-align: left;

		> select {
			display: inline;
			-webkit-appearance: none;

			border: 0;
			outline: 0;
			height: 40px;
			padding-left: 25px;
			padding-right: 50px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			font-family: 'Montserrat', sans-serif;
			font-size: 12px;
			font-weight: normal;
			cursor: pointer;

			margin-left: 15px;

			text-transform: uppercase;

			background-image: url('../../../assets/expand.svg');
			background-position: calc(100% - 10px) center;
			background-repeat: no-repeat;

			transition: box-shadow 0.1s;

			&:hover {
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
			}
		}
	}

	> div.charts {
		background: #f5f5f5;
		display: grid;
		padding: 25px;
		grid-template-columns: 50px 1fr 1fr 1fr;
		grid-column-gap: 25px;
		grid-row-gap: 25px;
		font-size: 10px;
		text-transform: uppercase;
		text-align: center;

		> div.title {
			writing-mode: vertical-rl;
			text-orientation: mixed;
			transform: rotate(180deg);
		}
	}
}

.progressContainer {
	width: 100%;
	background: rgba(0, 0, 0, 0.05);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 1px;

	display: table;
	table-layout: fixed;

	> div {
		display: table-cell;
		position: relative;
		height: 75px;
		vertical-align: middle;
		&:first-of-type {
			width: 150px;
			padding-left: 25px;
		}
		font-size: 12px;
		text-transform: uppercase;

		> div.fill {
			position: absolute;
			top: 15px;
			left: 15px;
			width: calc(100% - 30px);
			height: calc(100% - 30px);
			background: rgba(0, 0, 0, 0.05);
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			overflow: hidden;

			> div {
				position: absolute;
				top: 0px;
				left: 0px;
				height: 100%;
				background: #2a5f7a;

				> div {
					position: absolute;
					top: 50%;
					width: 100%;
					transform: translateY(-50%);
					color: white;
					text-align: center;
					font-weight: bold;
					font-size: 14px;
				}
			}
		}
	}
}

@media print {
	.progressContainer > div > div.fill > div {
		border-right: 1px solid black;
		> div {
			color: black;
		}
	}
}
