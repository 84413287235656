.compTable {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	height: 100%;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.1);

	> div {
		font-size: 14px;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;

		color: rgba(0, 0, 0, 0.25);

		&.red {
			color: #f44336;
		}
		&.green {
			color: #8bc34a;
		}

		&.sep {
			position: relative;
			&:after,
			&:before {
				position: absolute;
				content: '';
				top: 50%;
				transform: translateY(-50%);
				width: 14px;
				height: 14px;
				background-image: url('../../../../assets/arrow.svg');
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				/*background: rgba(0, 0, 0, 0.25);*/
				opacity: 0.25;
			}

			&:after {
				left: -7px;
			}

			&:before {
				right: -7px;
			}
		}
	}
}
