.popupTable {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-top: 25px;

    > div {
        display: table-cell;

        h4 {
            font-size: 12px;
            font-weight: normal;
            margin: 0;
            margin-bottom: 5px;
        }

        h2 {
            font-size: 16px;
            font-weight: bold;
            margin: 0;
        }

        ul,
        ol {
            padding-left: 25px;
            margin-top: 15px;
            margin-bottom: 15px;
            > li {
                font-size: 12px;
                margin-bottom: 5px;
            }
        }
    }
}

.popupButtons {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;

    > button {
        outline: 0;
        border: 0;
        height: 50px;
        cursor: pointer;
        background: linear-gradient(#4a8bab, #2a5f7a);
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        color: white;
        font-size: 12px;
        border-radius: 4px;
        cursor: pointer;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
        will-change: filter;

        &.red {
            background: linear-gradient(rgb(211, 97, 97), rgb(190, 35, 35));
        }

        &:hover {
            filter: brightness(115%);
        }

        &:disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}

.popup {
	> h3 {
		margin: 0px;
		margin-bottom: 10px;
		font-size: 20px;
	}

	> p {
		font-size: 14px;
		margin-bottom: 10px;
		margin-top: 0px;
	}

	> hr {
		height: 1px;
		background-color: rgba(0, 0, 0, 0.1);
		margin-top: 15px;
		margin-bottom: 15px;
		border: none;
	}

	> select {
		width: 100%;
		height: 50px;
		padding: 0 25px;
		border: 0;
		outline: 0;
		margin-bottom: 15px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		font-size: 14px;
		font-weight: normal;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) inset;
		font-family: 'Montserrat', sans-serif;
		-webkit-appearance: none;
		background-color: white;
		background-image: url('../../assets/expand.svg');
		background-position: calc(100% - 25px) center;
		background-repeat: no-repeat;

		&::placeholder {
			font-style: italic;
		}
	}
}
