div.obfuscator {
	cursor: wait;
	pointer-events: all !important;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.9);
	z-index: 255;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

div.content {
	margin: 0 auto;
	padding-top: 50px;
	width: 100%;
	max-width: 720px;
	text-align: left;

	&.loading {
		cursor: wait;
		opacity: 0.75;

		button {
			pointer-events: none !important;
			* {
				pointer-events: none !important;
			}
		}

		* {
			pointer-events: none !important;
		}
	}

	> img {
		height: 50px;
	}

	> div.section {
		width: 100%;
		border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
		box-sizing: border-box;
		padding-top: 50px;
		padding-bottom: 30px;

		> h2 {
			background: linear-gradient(#4a8bab, #2a5f7a);
			width: 80px;
			display: inline-block;
			height: 80px;
			line-height: 80px;
			border-radius: 100%;
			color: white;
			font-size: 30px;
			font-weight: bold;
			margin-top: 0px;
			margin-bottom: 30px;
			text-align: center;
			margin-left: 50%;
			transform: translateX(-50%);
		}

		> h3 {
			font-size: 20px;
			margin-top: 0px;
			margin-bottom: 30px;
			text-align: center;
		}

		> h4 {
			margin-top: 0px;
			font-size: 15px;
			font-weight: normal;
			margin-bottom: 30px;
			text-align: justify;
		}

		> div.nav {
			display: table;
			width: 100%;

			> div {
				display: table-cell;
				padding-top: 30px;
				button {
					outline: 0;
					border: 0px;
					height: 50px;
					cursor: pointer;
					background: linear-gradient(#4a8bab, #2a5f7a);
					font-weight: bold;
					font-family: 'Montserrat', sans-serif;
					color: white;
					font-size: 14px;
					border-radius: 4px;
					cursor: pointer;
					box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
					will-change: filter;
					width: 100%;
					position: relative;

					transition: filter 0.1s, color 0.1s;

					&.loading {
						color: transparent;

						> img.loading {
							opacity: 1;
						}
					}

					> img {
						height: 24px;
						vertical-align: -6px;
						margin-right: 10px;
					}

					> img.loading {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						height: 30px;
						opacity: 0;
						pointer-events: none !important;
						transition: opacity 0.1s;
					}

					&:hover {
						filter: brightness(115%);
					}

					&:disabled {
						pointer-events: none !important;
						opacity: 0.5;
						* {
							pointer-events: none !important;
						}
					}
				}
			}
		}

		ol {
			margin-bottom: 30px;

			li {
				img {
					vertical-align: -6px;
					cursor: pointer;
				}

				&.incorrect {
					color: rgba(255, 0, 0, 0.75);
				}
			}

			p {
				margin: 0px;
				font-size: 12px;
			}
		}

		div.bound {
			border: 1px solid rgba(0, 0, 0, 0.0625);
			padding: 25px;
			border-radius: 4px;
			background-color: rgba(0, 0, 0, 0.025);

			> h3 {
				margin-top: 0px;
				margin-bottom: 25px;
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
				padding-bottom: 25px;
			}

			button {
				width: 100%;
				margin-top: 25px;
				outline: 0;
				border: 0px;
				height: 50px;
				cursor: pointer;
				background: linear-gradient(#4a8bab, #2a5f7a);
				font-weight: bold;
				font-family: 'Montserrat', sans-serif;
				color: white;
				font-size: 14px;
				border-radius: 4px;
				cursor: pointer;
				box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
				will-change: filter;
				position: relative;

				transition: filter 0.1s, color 0.1s;

				&.loading {
					color: transparent;

					> img.loading {
						opacity: 1;
					}
				}

				> img.loading {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					height: 30px;
					opacity: 0;
					pointer-events: none !important;
					transition: opacity 0.1s;
				}

				&:hover {
					filter: brightness(115%);
				}

				&:disabled {
					pointer-events: none !important;
					opacity: 0.5;
					* {
						pointer-events: none !important;
					}
				}
			}
		}

		div.form {
			display: table;
			table-layout: fixed;
			width: 100%;
			> div {
				display: table-row;

				&:last-of-type > div {
					padding-bottom: 0px;
				}

				> div {
					display: table-cell;
					vertical-align: middle;
					padding-bottom: 15px;

					&:first-of-type {
						width: 200px;
						font-size: 14px;
					}

					&:nth-of-type(2) {
						input {
							width: 100%;
							height: 50px;
							padding: 0 25px;
							border: 0;
							outline: 0;
							border: 1px solid rgba(0, 0, 0, 0.1);
							border-radius: 4px;
							font-size: 14px;
							font-weight: normal;
							box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) inset;
							font-family: 'Montserrat', sans-serif;

							&::placeholder {
								font-style: italic;
							}

							&:disabled {
								color: rgba(0, 0, 0, 0.75);
								font-weight: bold;
							}

							&.wrongCreds {
								border: 1px solid rgba(255, 0, 0, 0.5);
							}
						}
					}
				}
			}
		}

		> div.singleInput {
			input {
				width: 100%;
				height: 50px;
				padding: 0 25px;
				border: 0;
				outline: 0;
				margin-bottom: 30px;
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-radius: 4px;
				font-size: 14px;
				font-weight: normal;
				box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) inset;
				font-family: 'Montserrat', sans-serif;

				float: left;

				width: calc(100% - 200px);

				&::placeholder {
					font-style: italic;
				}

				&.wrongCreds {
					border: 1px solid rgba(255, 0, 0, 0.5);
				}
			}

			button {
				outline: 0;
				border: 0px;
				height: 50px;
				cursor: pointer;
				background: linear-gradient(#4a8bab, #2a5f7a);
				font-weight: bold;
				font-family: 'Montserrat', sans-serif;
				color: white;
				font-size: 14px;
				border-radius: 4px;
				cursor: pointer;
				box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
				will-change: filter;
				width: 185px;
				position: relative;
				margin-left: 15px;

				transition: filter 0.1s, color 0.1s;

				&.loading {
					color: transparent;

					> img.loading {
						opacity: 1;
					}
				}

				> img.loading {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					height: 30px;
					opacity: 0;
					pointer-events: none !important;
					transition: opacity 0.1s;
				}

				&:hover {
					filter: brightness(115%);
				}

				&:disabled {
					pointer-events: none !important;
					opacity: 0.5;
					* {
						pointer-events: none !important;
					}
				}
			}
		}
	}

	.hiddenStep {
		display: none;
	}

	.disabled {
		pointer-events: none !important;
		opacity: 0.25;
		* {
			pointer-events: none !important;
		}
	}
}
