.conditions {
  position: fixed;
  height: 75px;
  z-index: 255;
  display: grid;
  width: min(calc(100% - 25px), 1200px);
  top: 75px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  grid-template-columns: 4fr 1fr;
  font-size: 14px;

  > div {
    display: flex;
    align-items: center;

    > p {
      margin-right: 10px;
    }

    > button {
      font-size: 12px;
      background: #2a5f7a;
      color: white;
      height: 25px;
      width: 30px;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 10px;
      outline: 0;
      border: 0;

      > img {
        height: 15px;
        vertical-align: -4px;
      }

      &:hover {
        background: #4a8bab;
      }
    }

    .condition {
      display: inline-block;
      background: rgb(245, 245, 245);
      padding: 5px 15px;
      border-radius: 4px;
      margin-right: 10px;
    }

    &:last-of-type {
      text-align: right;
      justify-content: flex-end;

      > img {
        margin-left: 12px;
        opacity: 0.25;
        cursor: pointer;
        transition: opacity 0.1s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
