div.popup {
  div.newTeamListing {
    background: rgba(0, 0, 0, 0.05);
    padding: 25px;
    border-radius: 5px;
    margin-top: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    > div {
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;

      > h4 {
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 0px;
        margin-bottom: 10px;
      }

      ul {
        margin-top: 10px;
        margin-bottom: 25px;
        padding-left: 20px;
        list-style-type: circle;
        > li {
          font-size: 14px;
          > p {
            margin-top: 0px;
            margin-bottom: 10px;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.5);
            text-transform: uppercase;
          }
        }
      }
    }
  }

  div.merging {
    display: grid;
    grid-template-columns: 1fr 50px 1fr;

    > * {
      align-self: center;
    }

    > select {
      width: 100%;
      -webkit-appearance: none;
      margin: 0;
      padding: 0px 25px;
      padding-right: 50px;
      height: 40px;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.05);
      background-image: url('../../assets/expand.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 25px) center;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;

      &.noninteractive {
        pointer-events: none;
        background-image: none;
      }
    }

    > img {
      justify-self: center;
    }
  }

  h2 {
    font-size: 20px;
    margin: 0px;
    margin-bottom: 5px;
  }
  h3 {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 25px;
  }
  p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 10px;
  }
  hr {
    height: 1px;
    border: 0;
    background: rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    margin-top: 25px;
  }
  button {
    outline: 0;
    border: 0px;
    height: 50px;
    cursor: pointer;
    background: linear-gradient(#4a8bab, #2a5f7a);
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    will-change: filter;
    width: calc(50% - 5px);
    margin-right: 10px;
    position: relative;

    &:last-of-type {
      margin-right: 0px;
    }

    transition: filter 0.1s, color 0.1s;

    &.loading {
      color: transparent;

      > img.loading {
        opacity: 1;
      }
    }

    > img.loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 30px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.1s;
    }

    &:hover {
      filter: brightness(115%);
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

div.management {
  background: #fafafa;
  padding: 25px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;

  > h3 {
    margin-top: 0px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;

    > img {
      height: 18px;
      vertical-align: -3px;
      margin-right: 10px;
    }
  }

  > div.entry {
    display: grid;
    grid-template-columns: 40px 1fr auto;
    align-items: center;
    justify-content: left;

    margin-bottom: 15px;

    border-radius: 4px;
    background: #f5f5f5;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);

    h4 {
      font-size: 18px;
      margin-top: 0px;
      margin-bottom: 2px;
      display: inline;
      font-weight: bold;
    }

    p {
      font-size: 10px;
      color: rgba(0, 0, 0, 0.5);
      margin: 0;
      margin-top: 5px;
      text-transform: uppercase;
    }

    &:hover {
      button {
        opacity: 1;
        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        }
      }
      > div:last-of-type > img {
        opacity: 0.5;
      }
    }

    div.data {
      background: white;
      padding: 25px;
      border-radius: 5px;
      margin-top: 15px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      > div {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;

        > h4 {
          font-size: 12px;
          text-transform: uppercase;
          margin-top: 0px;
          margin-bottom: 10px;
        }

        ul {
          margin-top: 10px;
          margin-bottom: 25px;
          padding-left: 20px;
          list-style-type: circle;
          > li {
            font-size: 14px;
            > p {
              margin-top: 0px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    button {
      background: white;
      display: inline-block;
      color: initial;
      box-shadow: 0;
      font-size: 10px;
      width: auto;
      height: auto;
      margin-right: 5px;
      font-weight: normal;
      padding: 5px 15px;
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transition: opacity 0.1s, box-shadow 0.1s;
      border-radius: 4px;
      cursor: pointer;
      font-family: 'Montserrat', sans-serif;

      &:disabled {
        opacity: 0.25;
        pointer-events: none;
      }

      > img {
        height: 15px;
        vertical-align: -4px;
      }

      &:hover {
        filter: none;
      }
    }
  }
}
