.chapter {
  margin-bottom: 10px;
  max-height: 1000px;
  transition: height .5s, max-height .5s;

  &.collapsed {
    max-height: 50px;
    overflow: hidden;
  }

  &.completed {
    pointer-events: none !important;
  }

  > div.title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(#4a8bab, #2a5f7a);
    padding: 0 25px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px 4px 0 0;
    position: relative;

    &.collapsed {
      border-radius: 4px;

      img.expand {
        display: block;
      }

      img.collapse {
        display: none;
      }
    }

    img {
      height: 18px;

      &.expand,
      &.collapse {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.completed,
      &.incomplete {
        vertical-align: -3px;
        margin-right: 10px;
      }
    }

    img.expand {
      display: none;
    }

    img.collapse {
      display: block;
    }
  }

  > div.variables {
    display: table;
    table-layout: fixed;
    width: 100%;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0625);

    > div.variable {
      display: table-row;
      background: rgb(245, 245, 245);

      &:first-of-type {
        background: rgb(250, 250, 250);
        > div {
          font-size: 10px;
          text-align: center;
          padding: 10px;
          text-transform: uppercase;
        }
      }

      &:last-of-type {
        > div {
          border-bottom: 0px;
        }
      }

      > div {
        border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
        border-right: 1px solid rgba(0, 0, 0, 0.0625);
        display: table-cell;
        vertical-align: middle;
        padding: 25px;
        //padding-bottom: 0px;
        font-size: 12px;

        &:first-of-type {
          border-left: 1px solid rgba(0, 0, 0, 0.0625);
        }

        &:nth-of-type(even) {
          background-color: rgba(0, 0, 0, 0.01);
        }

        &:first-of-type {
          width: 50%;
        }
      }

      &:last-of-type > div {
        padding-bottom: 25px;
      }
    }
  }
}
