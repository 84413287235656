.footer {
	height: 75px;

	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.5);
	border-top: 1px solid rgba(0, 0, 0, 0.1);

	> div {
		text-align: center;
	}
}
