.result {
  margin-bottom: 10px;
  > div.title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(#4a8bab, #2a5f7a);
    padding: 0 25px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px 4px 0 0;
    position: relative;
    will-change: filter;
    transition: filter 0.1s;
    > img {
      height: 18px;
      vertical-align: -3px;
      margin-right: 10px;
    }
    > .buttons {
      position: absolute;
      right: 25px;
      top: 50%;
      display: grid;
      grid-auto-columns: min-content;
      grid-auto-flow: column;
      grid-column-gap: 10px;
      transform: translateY(-50%);
      > img {
        align-self: center;
      }
    }

    &.printMode {
      background: white;
      color: black;
      border: 3px solid black;
      border-bottom: 3px solid black;
      height: 55px;
      position: relative;

      > img.miniLogo {
        display: block !important;
        position: absolute;
        height: 30px;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
      }

      > .buttons,
      img {
        display: none;
      }
    }

    cursor: pointer;
    &:hover {
      filter: brightness(110%);
    }
  }
  > div.breakdown {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0 0 4px 4px;
    overflow: hidden;

    &.printMode {
      border: 3px solid black;
      border-top: 0px;
      border-bottom: 2px solid black;

      div.spacer {
        background-color: black;
      }

      div.breakdownShort {
        background: white;
        > div {
          padding: 15px;

          &:not(.sep) {
            border-left: 1px solid black;
          }
          &:first-of-type,
          &:last-of-type,
          &:nth-of-type(2) {
            border-left: 0px;
          }
          &.accent {
            background-color: white;
          }
          &.sep::after {
            background: black;
          }
        }
      }

      div.breakdownTitle {
        background: white;
        > div {
          background-color: white;
          border-left: 1px solid black;
          border-bottom: 1px solid black;
          &:first-of-type,
          &:last-of-type,
          &:nth-of-type(2) {
            border-left: 0px;
          }
          &.accent {
            background-color: white;
          }
        }
      }
    }

    div.breakdownTitle {
      display: grid;
      grid-template-columns: 4fr 2fr 2fr 2fr 1fr;
      background: #fafafa;
      > div {
        font-size: 10px;
        text-align: center;
        padding: 10px;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        &.accent {
          background-color: rgba(0, 0, 0, 0.02);
        }
      }
    }
    div.spacer {
      width: 100%;
      height: 3px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    div.breakdownShort {
      display: grid;
      grid-template-columns: 4fr 2fr 1fr 1fr 1fr 1fr 1fr;
      background: #f5f5f5;

      > div {
        overflow: hidden;
        vertical-align: middle;
        padding: 25px;
        font-size: 14px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;

        position: relative;

        > img {
          height: 14px;
          margin-left: 5px;
        }

        &.accent {
          background-color: rgba(0, 0, 0, 0.02);
        }

        > button {
          background: white;
          color: initial;
          box-shadow: 0;
          font-size: 10px;
          width: auto;
          height: auto;
          margin: 0;
          margin-left: 15px;
          font-weight: normal;
          padding: 5px 15px;
          box-shadow: none;
          border: 1px solid rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.1s;
          border-radius: 4px;
          cursor: pointer;
          font-family: 'Montserrat', sans-serif;

          > img {
            height: 15px;
            vertical-align: -4px;
          }

          &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
          }
        }

        &.sep {
          &::after {
            position: absolute;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            left: -1px;
            width: 2px;
            height: 15px;
            background: rgba(0, 0, 0, 0.25);
          }
          color: rgba(0, 0, 0, 0.25);
          &.red {
            color: #f44336;
          }
          &.green {
            color: #8bc34a;
          }
        }

        &:first-of-type {
          font-size: 12px;
          text-align: left;
          justify-content: left;
          font-weight: normal;
        }
      }
    }
    div.perfChartExpander {
      width: 100%;
      text-align: right;
      padding: 10px;
      padding-right: 25px;
      background-color: #fafafa;
      font-size: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.05);

      &.expanded {
        border-bottom: 1px solid rgba(0, 0, 0, 0.055);
      }

      > button {
        background: white;
        color: initial;
        box-shadow: 0;
        font-size: 10px;
        width: auto;
        height: auto;
        margin: 0;
        margin-left: 15px;
        font-weight: normal;
        padding: 5px 15px;
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.1s;
        border-radius: 4px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;

        > img {
          height: 15px;
          vertical-align: -4px;
          margin-right: 10px;
        }

        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
