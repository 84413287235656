.container {
	margin: 0 auto;
	width: min(calc(100% - 25px), 1200px);
	margin-top: 90px;

	> div.pusher {
		min-height: calc(100vh - 165px);
	}
}

@media print {
	.container {
		margin-top: 0px;
	}
}
