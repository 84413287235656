.vaultView {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	background: #fafafa;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	overflow: hidden;

	> div {
		font-size: 10px;
		text-align: center;
		padding: 25px;
		text-transform: uppercase;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);

		&.firstRow {
			> p {
				margin-bottom: 0px;
				margin-top: 5px;
				font-size: 8px;
			}

            &.darker {
                background: rgb(246, 246, 246);
            }
		}

		&:not(.firstRow) {
			background: #f5f5f5;

			&.darker {
				background: rgba(0, 0, 0, 0.04) !important;
			}
		}

		&.lastRow {
			border-bottom: 0px;
		}

		&.question {
			display: flex;
			align-items: center;
		}

		&.score {
			font-size: 14px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
		}
	}
}
