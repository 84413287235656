div.universalQuery {
  background: #fafafa;
  padding: 25px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;

  > h3 {
    margin-top: 0px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;

    > img {
      height: 18px;
      vertical-align: -3px;
      margin-right: 10px;
    }
  }

  > div.report {
    display: grid;
    grid-template-columns: 40px 1fr 250px;
    align-items: center;
    justify-content: left;

    margin-bottom: 15px;

    border-radius: 4px;
    background: #f5f5f5;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);

    h4 {
      font-size: 18px;
      margin-top: 0px;
      margin-bottom: 2px;
      display: inline;
      font-weight: bold;
    }

    p {
      font-size: 10px;
      color: rgba(0, 0, 0, 0.5);
      margin: 0;
      margin-top: 5px;
      text-transform: uppercase;
    }

    &:hover {
      button {
        opacity: 1;
        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        }
      }
      > div:last-of-type > img {
        opacity: 0.5;
      }
    }

    button {
      background: white;
      color: initial;
      box-shadow: 0;
      font-size: 10px;
      width: auto;
      height: auto;
      margin: 0;
      margin-left: 15px;
      font-weight: normal;
      padding: 5px 15px;
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      //opacity: 0;
      transition: opacity 0.1s, box-shadow 0.1s;
      border-radius: 4px;
      cursor: pointer;
      font-family: 'Montserrat', sans-serif;

      > img {
        height: 15px;
        vertical-align: -4px;
      }

      &:hover {
        filter: none;
      }
    }
  }
}
