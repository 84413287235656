.checkbox {
  width: 25px;
  height: 25px;
  border: 2px solid black;
  border-radius: 15px;
  display: block;
  position: relative;
  cursor: pointer;
  margin: 0 auto;

  &.submitted:not(.selected) {
    opacity: 0.25;
  }

  &::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    bottom: 3px;
    right: 3px;
    background: black;
    border-radius: 100%;
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 0.125s, transform 0.25s;
  }

  &:hover::after,
  &.selected::after {
    opacity: 1;
    transform: scale(1);
  }
}
