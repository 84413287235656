div.tButton {
	background: #fafafa;
	padding: 25px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	min-height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: background 0.1s, box-shadow 0.1s;
	position: relative;
	overflow: hidden;

	> img.loader {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
	}

	&.loading {
		pointer-events: none;
		&:after {
			content: '';
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.95);
			z-index: 1;
		}
		> img.loader {
			display: block;
		}
	}

	&:hover {
		background: white;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
	}

	> div {
		width: 100%;
	}

	img {
		width: 50px;
	}

	hr {
		height: 1px;
		background: rgba(0, 0, 0, 0.1);
		border: 0;
		width: 100%;
		margin-top: 12.5px;
		margin-bottom: 12.5px;
	}
}
