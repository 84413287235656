.vaultTable {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	height: 100%;

	> div {
		font-size: 14px;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;

		color: rgba(0, 0, 0, 0.25);

		&.red {
			color: #f44336;
		}
		&.green {
			color: #8bc34a;
		}

		&.sep {
			position: relative;
			&:after,
			&:before {
				position: absolute;
				content: '';
				top: 50%;
				transform: translateY(-50%);
				width: 1px;
				height: 15px;
				background: rgba(0, 0, 0, 0.25);
			}

			&:after {
				left: -1px;
			}

			&:before {
				right: -1px;
			}
		}
	}
}
