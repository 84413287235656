.queryBrowser {
	width: 100%;
	display: grid;
	//grid-template-columns: 1fr 1fr;
	grid-template-columns: 1fr;
	grid-gap: 15px;
	margin-bottom: 15px;

	p.noFavorites {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.5);
		font-style: italic;
	}

	> div {
		background: #fafafa;
		padding: 25px;
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.1);

		> h3 {
			margin-top: 0px;
			padding-bottom: 15px;
			margin-bottom: 15px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			font-size: 14px;

			> img {
				height: 18px;
				vertical-align: -3px;
				margin-right: 10px;
			}
		}

		> div.favorite {
			display: grid;
			//grid-template-columns: 2fr 1fr 40px;
			grid-template-columns: 1fr 250px;
			align-items: center;
			justify-content: left;

			margin-bottom: 15px;

			border-radius: 4px;
			background: #f5f5f5;
			padding: 15px;
			border: 1px solid rgba(0, 0, 0, 0.05);

			h4 {
				font-size: 18px;
				margin-top: 0px;
				margin-bottom: 2px;
				cursor: pointer;
				display: inline;
				font-weight: bold;

				&:hover {
					text-decoration: underline;
				}
			}

			p {
				font-size: 10px;
				color: rgba(0, 0, 0, 0.5);
				margin: 0;
				margin-top: 5px;
				text-transform: uppercase;
			}

			&:hover {
				button {
					opacity: 1;
					&:hover {
						box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
					}
				}
				> div:last-of-type > img {
					opacity: 0.5;
				}
			}

			button {
				background: white;
				color: initial;
				box-shadow: 0;
				font-size: 10px;
				width: auto;
				height: auto;
				margin: 0;
				margin-left: 15px;
				font-weight: normal;
				padding: 5px 15px;
				box-shadow: none;
				border: 1px solid rgba(0, 0, 0, 0.1);
				//opacity: 0;
				transition: opacity 0.1s, box-shadow 0.1s;
				border-radius: 4px;
				cursor: pointer;
				font-family: "Montserrat", sans-serif;

				> img {
					height: 15px;
					vertical-align: -4px;
				}

				&:hover {
					filter: none;
				}
			}

			/*> div:last-of-type { // uncomment if removing favorites will be an option
				text-align: right;
				> img {
					transition: opacity 0.1s;
					vertical-align: -2px;
					opacity: 0.25;
					cursor: pointer;
					&:hover {
						opacity: 1;
					}
				}
			}*/
		}
	}
}
