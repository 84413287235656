.rocChart {

	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 10px;

	> div.barContainer {
        margin-top: 15px;
        position: relative;
        min-height: 200px;

        > div.bar {
            position: absolute;
            left: 0px;
            width: 100%;
            height: 100%;
            bottom: 0px;
            background: #2a5f7a;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 0px;

            > div {
                position: absolute;
                top: -25px;
                font-weight: bold;
                left: 0px;
                width: 100%;
                text-align: center;
                font-size: 14px;
            }
        }
	}

    > div.subtitle {
        padding: 10px;
        background: rgba(0, 0, 0, 0.05);
        font-weight: bold;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
}
