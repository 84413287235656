.eval {
  &.print {
    background-color: white;
    > div {
      background-color: white;
      > div {
        padding: 15px;
        border-left: 1px solid black;
        border-bottom: 1px solid black;
        &:first-of-type,
        &:last-of-type,
        &:nth-of-type(2),
        &.sep {
          border-left: 0px;
        }
        &.sep::after {
          background: black;
        }
        &.accent {
          background-color: white;
        }
      }
    }
  }

  > div {
    display: grid;
    grid-template-columns: 4fr 2fr 1fr 1fr 1fr 1fr 1fr;
    background-color: #f5f5f5;

    &:not(.extended) {
      transition: background 0.1s;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      &:hover {
        background: #fafafa;
      }
    }

    &.top {
      cursor: pointer;
      &.noborder {
        border-bottom: 0px !important;
      }
    }

    &.extended {
      background-color: rgb(237, 237, 237);

      /*&:last-of-type*/
      > div {
        border-bottom: 1px solid rgba(0, 0, 0, 0.025);
      }

      > div {
        &:first-of-type {
          padding-left: 50px;
          font-style: italic;
        }
      }
    }

    > div {
      overflow: hidden;
      vertical-align: middle;
      padding: 25px;
      font-size: 14px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;

      position: relative;

      > img {
        height: 14px;
        margin-left: 5px;
      }

      &.accent {
        background-color: rgba(0, 0, 0, 0.02);
      }

      &.sep {
        &::after {
          position: absolute;
          content: '';
          top: 50%;
          transform: translateY(-50%);
          left: -1px;
          width: 2px;
          height: 15px;
          background: rgba(0, 0, 0, 0.25);
        }
        color: rgba(0, 0, 0, 0.25);
        &.red {
          color: #f44336;
        }
        &.green {
          color: #8bc34a;
        }
      }

      &:first-of-type {
        font-size: 12px;
        text-align: left;
        justify-content: left;
        font-weight: normal;
      }
    }
  }
}

img.expand,
img.collapse {
  width: 24px;
}
