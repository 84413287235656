.queryInput {
	width: 100%;

	button {
		width: 300px;
		margin: 0 auto;
		outline: 0;
		border: 0;
		background: linear-gradient(#4a8bab, #2a5f7a);
		height: 40px;
		font-weight: bold;
		font-family: 'Montserrat', sans-serif;
		color: white;
		font-size: 14px;
		border-radius: 4px;
		cursor: pointer;
		box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);

		&:hover {
			filter: brightness(115%);
		}

		&:disabled {
			pointer-events: none;
			opacity: 0.5;
		}
	}

	> .queryInputTitle {
		width: 100%;
		height: 50px;
		line-height: 50px;
		background: linear-gradient(#4a8bab, #2a5f7a);
		padding: 0 25px;
		color: white;
		font-size: 14px;
		font-weight: bold;
		border-radius: 4px 4px 0 0;

		transition: filter 0.1s;
		cursor: pointer;

		position: relative;

		&.collapsed {
			border-radius: 5px;
		}

		> img {
			height: 18px;
			vertical-align: -3px;

			&.exp {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 25px;
			}
		}

		&:hover {
			filter: brightness(110%);
		}
	}

	> .queryInputContent {
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 0 0 4px 4px;
		padding: 25px;

		> h2 {
			font-size: 16px;
			font-weight: bold;
			margin: 0;
		}

		> h3 {
			font-size: 14px;
			font-weight: normal;
			margin: 0;
			margin-top: 5px;
		}

		ul {
			list-style-type: none;
			padding-left: 15px;
			li {
				font-size: 14px;
			}
		}

		> select {
			width: 100%;
			height: 40px;
			padding: 0 25px;
			border: 0;
			outline: 0;
			margin-bottom: 0px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			font-size: 12px;
			font-weight: normal;
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) inset;
			font-family: 'Montserrat', sans-serif;
			-webkit-appearance: none;
			background-color: white;
			background-image: url('../../../assets/expand.svg');
			background-position: calc(100% - 25px) center;
			background-repeat: no-repeat;

			cursor: pointer;
			margin-top: 15px;

			&::placeholder {
				font-style: italic;
			}
		}

		> div.querySetter {
			display: table;
			table-layout: fixed;
			width: 100%;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			margin-top: 25px;
			font-size: 14px;

			input {
				width: 100%;
				height: 40px;
				padding: 0 25px;
				border: 0;
				outline: 0;
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-radius: 4px;
				font-size: 12px;
				font-weight: normal;
				box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) inset;
				font-family: 'Montserrat', sans-serif;

				&::placeholder {
					font-style: italic;
				}
			}

			select {
				width: 100%;
				height: 40px;
				padding: 0 25px;
				border: 0;
				outline: 0;
				margin-bottom: 0px;
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-radius: 4px;
				font-size: 12px;
				font-weight: normal;
				box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) inset;
				font-family: 'Montserrat', sans-serif;
				-webkit-appearance: none;
				background-color: white;
				background-image: url('../../../assets/expand.svg');
				background-position: calc(100% - 25px) center;
				background-repeat: no-repeat;

				cursor: pointer;

				&::placeholder {
					font-style: italic;
				}
			}

			> div {
				display: table-row;

				&:first-of-type {
					> div {
						padding-bottom: 10px;
						background-color: rgba(0, 0, 0, 0.05);
						font-size: 10px;
						font-weight: bold;
					}
				}

				&:last-of-type {
					> div {
						padding-bottom: 15px;
					}
				}

				> div {
					display: table-cell;
					padding: 15px;
					padding-bottom: 0px;

					&:nth-of-type(3) {
						width: 150px;
					}

					button {
						width: calc(50% - 5px);
						&:first-of-type {
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
}
