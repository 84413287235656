.header {
	position: fixed;
	top: 0;
	width: 100%;
	height: 75px;
	background: white;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	z-index: 254;

	> div {
		display: table;
		table-layout: fixed;
		width: min(calc(100% - 25px), 1200px);
		margin: 0 auto;
		height: 75px;

		> div {
			display: table-cell;
			vertical-align: middle;

			> img {
				height: 40px;
				vertical-align: middle;
			}

			&:nth-of-type(2) {
				text-align: right;
			}
		}
	}

	.logout {
		font-size: 12px;
		color: #2a5f7a;
		cursor: pointer;
		text-decoration: underline;

		&:hover {
			color: #4a8bab;
		}
	}

	.print {
		font-size: 12px;
		background: #2a5f7a;
		color: white;
		padding: 5px 15px;
		border-radius: 5px;
		cursor: pointer;
		margin-right: 15px;

		> img {
			height: 15px;
			vertical-align: -2px;
			margin-right: 5px;
		}

		&:hover {
			background: #4a8bab;
		}
	}
}
