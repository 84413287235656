.loginBox {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  width: 100%;

  > div {
    margin: 0 auto;
    width: min(calc(100% - 25px), 450px);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 25px 25px 35px 25px;

    img.logo {
      height: 90px;
      margin-left: 50%;
      transform: translateX(-50%);
      margin-bottom: 25px;
    }

    h3 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 10px;
    }

    input {
      width: 100%;
      height: 50px;
      padding: 0 25px;
      border: 0;
      outline: 0;
      margin-bottom: 15px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      font-size: 14px;
      font-weight: normal;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) inset;
      font-family: 'Montserrat', sans-serif;

      &::placeholder {
        font-style: italic;
      }

      &:first-of-type {
        margin-bottom: 25px;
      }

      &.wrongCreds {
        border: 1px solid rgba(255, 0, 0, 0.5);
      }
    }

    p.incorrect {
      color: rgba(255, 0, 0, 0.5);
      margin: 0;
      font-size: 12px;
      margin-bottom: 15px;
    }

    p.forgotten {
      margin: 0;
      font-size: 12px;
      width: 100%;
      text-align: right;
      margin-top: 10px;

      > a {
        color: #2a5f7a;
        text-decoration: none;
        &:hover {
          color: #4a8bab;
        }
      }
    }

    hr {
      height: 1px;
      border: 0;
      background: rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;
      margin-top: 5px;
    }

    button {
      outline: 0;
      border: 0px;
      height: 50px;
      cursor: pointer;
      background: linear-gradient(#4a8bab, #2a5f7a);
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      color: white;
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
      will-change: filter;
      width: 100%;
      position: relative;

      transition: filter 0.1s, color 0.1s;

      &.loading {
        color: transparent;

        > img.loading {
          opacity: 1;
        }
      }

      > img.loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 30px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.1s;
      }

      &:hover {
        filter: brightness(115%);
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}
