@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
	font-family: 'Montserrat', sans-serif;
	margin: 0;

	* {
		box-sizing: border-box;
	}

	hr {
		height: 1px;
		background-color: rgba(0, 0, 0, 0.1);
		margin-top: 15px;
		margin-bottom: 15px;
		border: none;
	}
}

.onlyprint {
	display: none !important;
}

@media print {
	.noprint {
		display: none !important;
	}
	.onlyprint {
		display: initial !important;
	}
}
