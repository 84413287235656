.evalSubmit {
	padding-top: 25px;
	margin-top: 25px;
	padding-bottom: 25px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	text-align: right;

	> p {
		font-size: 12px;
		font-style: italic;
	}

	> button {
		width: 100%;
		margin: 0 auto;
		outline: 0;
		border: 0;
		background: linear-gradient(#4a8bab, #2a5f7a);
		height: 50px;
		font-weight: bold;
		font-family: 'Montserrat', sans-serif;
		color: white;
		font-size: 14px;
		border-radius: 4px;
		cursor: pointer;
		box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);

    >img {
      height: 24px;
      vertical-align: -6px;
      margin-left: 10px;
    }

		&:hover {
			filter: brightness(115%);
		}

		&:disabled {
			pointer-events: none;
			opacity: 0.5;
		}
	}
}
