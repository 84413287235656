.messageBox {
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	padding: 25px;
	margin-bottom: 15px;

	h2 {
		font-size: 16px;
		font-weight: 700;
		margin: 0 0 5px 0px;
	}

	> p {
		font-size: 14px;
		font-weight: 400;
		margin: 0 0 5px 0px;
		max-width: 800px;

		&:last-of-type {
			margin-bottom: 0px;
		}
	}

	span,
	a {
		color: #2a5f7a;
		cursor: pointer;
		text-decoration: underline;

		&:hover {
			color: #4a8bab;
		}
	}
}

@media print {
	.messageBox {
		border: 0px;
		padding: 0px;
		h2 {
			font-size: 12px;
		}
	}
}
