.navigator {
	display: inline;
    position: relative;

    > img.icon {
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
    }

	> select {
		-webkit-appearance: none;

        border: 0;
        margin-left: 25px;
        outline: 0;
        height: 40px;
        padding-left: 50px;
        padding-right: 50px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-weight: normal;
        cursor: pointer;

        background-image: url("../../../assets/expand.svg");
        background-position: calc(100% - 10px) center;
        background-repeat: no-repeat;
        
        transition: background-color .1s;

        &.single {
            pointer-events: none;
            background-image: none;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
        }
	}
}
