div.container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 256;

  &.triggered {
    background: rgba(0, 0, 0, 0.75);
  }

  &.loading {
    cursor: wait;
  }

  &.loading > div::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    background-image: url('../../assets/loading.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  > div {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 4px;
    padding: 25px 25px 35px 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
    position: relative;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 100px);
    max-width: 450px;

    > h2 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      text-align: left;
      margin-bottom: 10px;
    }

    > button {
      outline: 0;
      border: 0px;
      height: 50px;
      cursor: pointer;
      background: linear-gradient(#4a8bab, #2a5f7a);
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      color: white;
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
      will-change: filter;
      width: 100%;
      position: relative;
      text-align: left;
      padding: 0 25px;

      transition: filter 0.1s, color 0.1s;

      margin-top: 10px;

      > img {
        vertical-align: -7px;
        margin-right: 10px;
      }

      &:hover {
        filter: brightness(115%);
      }
    }
  }
}
